/* Hero Section */
.contact-hero {
  background-color: #007bff; /* Match background color */
  color: white;
  padding: 60px 0;
}

.contact-hero h1 {
  font-size: 3rem;
}

.contact-hero p {
  font-size: 1.25rem;
}

/* Contact Content */
.contact-content {
  padding: 40px 0;
}

.contact-info,
.contact-form-container {
  border: 1px solid #ddd;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.contact-info,
.contact-form-container {
  height: 100%;
}

.contact-info {
  padding: 20px;
}

.contact-form-container {
  padding: 20px;
}

.contact-item h5 {
  font-size: 1.1rem;
  font-weight: 600;
}

.map-container {
  margin-top: 20px;
}

.contact-form .form-group {
  margin-bottom: 20px;
}

.contact-form .form-control {
  border-radius: 4px;
  box-shadow: none;
}

.contact-form .btn-primary {
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  padding: 10px;
  font-size: 1rem;
}

.contact-form .btn-primary:hover {
  background-color: #0056b3;
}
