/* Basic Reset */
html, body {
  height: 100%;
  margin: 0;
  overflow-x: hidden;
  font-family: 'Arial', sans-serif;
}

/* Hero Section */
.hero-section {
  height: 100vh; /* Full viewport height */
  background: linear-gradient(45deg, rgb(101, 181, 232), rgba(0, 123, 255, 0.7)), url('../images/it.jpg'); /* Gradient + Background Image */
  background-size: cover;
  background-position: center;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.overlay {
  background: rgba(0, 0, 0, 0.5); /* Dark overlay for better text visibility */
  padding: 20px;
  border-radius: 10px;
}

/* Animation Styles */
.animate__animated {
  animation-duration: 1s;
}

.animate__fadeIn {
  animation-name: fadeIn;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Service Cards */
.service-card {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.service-card:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.icon {
  color: #007bff;
  font-size: 50px;
}

/* Testimonials Section */
.testimonials-section {
  background-color: #f8f9fa;
}

.card {
  border: none;
  border-radius: 8px;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: #007bff;
  border-radius: 50%;
}

/* Features Section */
.features-section {
  background-color: #e9ecef;
}

.feature-card {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.feature-card:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}
