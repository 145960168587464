/* Hero Section */
.about-hero {
  background: linear-gradient(135deg, #007bff, #00c6ff); /* Vibrant gradient */
  color: white;
  padding: 80px 0; /* Larger padding for visual impact */
}

/* Company Overview */
.company-overview {
  padding: 60px 0;
}

.company-overview .overview-img-container {
  overflow: hidden;
  border-radius: 8px;
}

.company-overview .overview-img-container img {
  border-radius: 8px;
}

/* Mission and Values */
.mission-values {
  padding: 60px 0;
}

.mission-values .values-list {
  list-style-type: none;
  padding: 0;
}

.mission-values .values-list li {
  font-size: 1.1rem;
  margin-bottom: 10px;
}

/* Company History */
.company-history {
  padding: 60px 0;
}

/* Team Section */
.team-section {
  padding: 60px 0;
}

.team-member {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  background: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.team-member img {
  border: 5px solid #007bff; /* Accent border color */
}

.team-member-name {
  font-size: 1.25rem;
  font-weight: bold;
}

.team-member-role {
  font-size: 1rem;
  color: #007bff; /* Accent color */
}

/* Testimonials Section */
.testimonials {
  padding: 60px 0;
}

.testimonial-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  background: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.testimonial-text {
  font-size: 1.1rem;
}

.testimonial-author {
  font-size: 1rem;
  color: #007bff; /* Accent color */
}

/* Contact Section */
.contact-info {
  background-color: #f8f9fa; /* Light background for contrast */
}

.contact-info .btn-primary {
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
}

.contact-info .btn-primary:hover {
  background-color: #0056b3;
}
